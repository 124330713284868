import { required, minLength, validEmail } from 'services/validators'

const signup = (values, currentPage) => {
  const errors = {}
  if (!required(values.email)) {
    errors.email = 'Email requerido'
  } else if (!validEmail(values.email)) {
    errors.email = 'Email inválido'
  }
  if (!required(values.firstName)) {
    errors.firstName = 'Nombre requerido'
  }
  if (!required(values.lastName)) {
    errors.lastName = 'Apellido requerido'
  }
  if (!required(values.telephone)) {
    errors.telephone = 'Teléfono requerido'
  }
  if (!required(values.password)) {
    errors.password = 'Contraseña requerida'
  } else if (!minLength(values.password, 6)) {
    errors.password = 'Mínimo 6 caracteres'
  }
  return errors
}

export default signup
