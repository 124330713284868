import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import classnames from 'classnames'
import Button from 'controls/Button'
import { Row, Col } from 'flexbox'
import { InputForm, NonFieldInputForm } from 'controls/Input'
import { DropDownForm } from 'controls/DropDown'
import signupValidator from 'app/validators/signup'
import { companyConfig } from 'globalConfigApp'

import styles from './styles'
import { common } from '../styles'

class SignupForm extends Component {
  static propTypes = {
    header: PropTypes.string.isRequired,
    subHeader: PropTypes.string.isRequired,
    onEmailBlur: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    showActions: PropTypes.bool,
    isForm: PropTypes.bool,
    centered: PropTypes.bool,
    invalid: PropTypes.bool,
    style: PropTypes.object
  }

  static defaultProps = {
    onEmailBlur: () => {},
    handleSubmit: () => console.log('submit from signup form'),
    showActions: true,
    isForm: true,
    centered: true,
    invalid: true,
    style: {}
  }

  render() {
    const {
      header,
      subHeader,
      handleSubmit,
      invalid,
      loading,
      style,
      centered,
      onEmailBlur,
      showActions,
      isForm
    } = this.props
    const Tag = isForm ? 'form' : 'div'
    const os = isForm ? { onSubmit: handleSubmit } : {}
    return (
      <Tag {...os}>
        <div className="login-container" style={style}>
          <Row>
            <Col xs={12}>
              <h3 className={classnames('header', { centered })}>{header}</h3>
              <h4 className={classnames('sub-header', { centered })}>
                {subHeader}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Field
                name="email"
                id="email-registration"
                className="form-input-margin"
                component={InputForm}
                placeholder="Correo electrónico"
                onBlur={onEmailBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Field
                component={NonFieldInputForm}
                name="duplicateEmail"
                className="form-input-margin"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Field
                name="firstName"
                id="first-name"
                className="form-input-margin"
                component={InputForm}
                placeholder="Nombre(s)"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Field
                name="lastName"
                id="last-name"
                className="form-input-margin"
                component={InputForm}
                placeholder="Primer apellido"
              />
            </Col>
            <Col xs={12} md={6}>
              <Field
                name="secondLastName"
                id="second-last-name"
                className="form-input-margin"
                component={InputForm}
                placeholder="Segundo apellido"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Field
                name="telephone"
                id="telephone"
                className="form-input-margin"
                component={InputForm}
                placeholder="Teléfono con (LADA)"
              />
            </Col>
            <Col xs={12} md={6}>
              <Field
                name="secondTelephone"
                id="second-telephone"
                className="form-input-margin"
                component={InputForm}
                placeholder="Otro teléfono"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Field
                name="password"
                id="password"
                className="form-input-margin"
                type="password"
                component={InputForm}
                placeholder="Ingresa una contraseña"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Field
                component={DropDownForm}
                name="fountBy"
                className="form-input-margin"
                editable={false}
                hideResetButton={true}
                placeholder={`¿Cómo te enteraste de ${companyConfig.name}?`}
                options={[
                  { value: 'Internet', label: 'Internet' },
                  { value: 'Facebook', label: 'Facebook' },
                  { value: 'Google', label: 'Google' }
                ]}
              />
            </Col>
          </Row>
          {showActions ? (
            <Row>
              <Col xs={12} className="actions">
                <Button
                  color="primary"
                  fullWidth
                  type="submit"
                  className="submit"
                  disabled={invalid}
                  loading={loading}
                >
                  Registrarse
                </Button>
              </Col>
            </Row>
          ) : null}
          <style jsx>{styles}</style>
          <style jsx>{common}</style>
        </div>
      </Tag>
    )
  }
}

const formConfig = {
  form: 'signupForm',
  validate: signupValidator
}

export const ReduxSignupForm = reduxForm(formConfig)(SignupForm)

export default SignupForm
