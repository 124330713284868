import css from 'styled-jsx/css'
import { colors, measurements, fontSizes } from 'theme'
import { iadProvitionalColors } from 'theme/colors'

export default css`
  .simple-login-container-background {
    border-right: 1px solid ${colors.border};
    border-left: 1px solid ${colors.border};
    background-position: center center;
    background-color: ${colors.lightGray};
    height: auto;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
  }

  .simple-login-container {
    display: flex;
    height: 100%;
    justify-content: space-evenly;
    max-width: 24rem;
    flex-direction: column;
    padding: 2rem;
    margin: 0 auto;
  }

  .logo {
    width: 5rem;
    margin-bottom: 1rem;
    margin: 0 auto 1rem;
  }
  .logo:hover {
    cursor: pointer;
  }

  .top-icon {
    width: 7rem;
    margin: 0 auto;
  }

  .title {
    font-weight: bold;
    text-align: center;
  }

  .text-to-evaluate {
    width: 100%;
    min-height: 2rem;
    margin-bottom: 1rem;
    border-radius: ${measurements.borderRadius};
    color: ${iadProvitionalColors.black}; 
    background-color: ${colors.alpha(colors.gray, 0.4)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: ${fontSizes.p};
    font-weight: bold;
    word-break: break-word;
    line-height: 1.5rem;
    padding: 0.5rem 0;
  }

  .controls {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1rem 0 2rem;
    justify-content: space-between;
  }

  .simple-login-container-background .controls :global(.cancel-button) {
    color: ${colors.secondary};
  }

  @media screen and (min-width: 992px) {
    .simple-login-container-background {
      border: none;
      max-width: 100%;
      background-position: bottom center;
      background-image: none !important; /* because the background-image comes from inline style */
    }
    .simple-login-container {
      max-width: 24rem;
    }
  }
`