/**
    * Migrated from 'components/Forms/Login/LoginForm.js' to avoid override neximo styles
    * This component will be used in the migration so it needs to have IAD look and feel
    * Sep, 2023
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Field, reduxForm } from 'redux-form'
import { Button } from 'controls'
import { Row, Col } from 'flexbox'

import { InputForm, NonFieldInputForm } from 'controls/Input'
import loginValidator from 'app/validators/login'

import { iadProvitionalColors } from 'theme/colors'

import styles from './styles'
import { common } from './commonStyles'

class LoginForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    centered: PropTypes.bool,
    invalid: PropTypes.bool,
    style: PropTypes.object,
  }

  static defaultProps = {
    handleSubmit: () => console.log('submit from login form'),
    showActions: true,
    centered: true,
    invalid: true,
    style: {},
  }

  render() {
    const { handleSubmit, invalid, style, centered, loading } = this.props
    return (
      <form className="login-container" onSubmit={handleSubmit} style={style}>
        <h3 className={classnames('header', { centered })}>
          Ingresa a <b>iad</b> México
        </h3>
        <h4 className={classnames('sub-header', { centered })}>
          Ingresa los detalles de tu cuenta a continuación
        </h4>
        <Row>
          <Col xs={12}>
            <Field
              className="form-input-margin black-text"
              component={NonFieldInputForm}
              name="nonFieldErrors"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Field
              className="form-input-margin"
              style={{ color: iadProvitionalColors.black }}
              name="email"
              id="email"
              component={InputForm}
              label="Correo electrónico"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Field
              className="form-input-margin"
              style={{ color: iadProvitionalColors.black }}
              name="password"
              id="password"
              type="password"
              component={InputForm}
              label="Contraseña"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="actions">
            <Button
              style={{
                backgroundColor: iadProvitionalColors.primary,
                color: iadProvitionalColors.white,
              }}
              fullWidth
              type="submit"
              className="submit"
              disabled={invalid}
              loading={loading}
            >
              Entrar
            </Button>
          </Col>
        </Row>
        <style jsx>{styles}</style>
        <style jsx>{common}</style>
      </form>
    )
  }
}

const formConfig = {
  form: 'loginForm',
  validate: loginValidator,
}

export const ReduxLoginFormIad = reduxForm(formConfig)(LoginForm)

export default LoginForm
