import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'controls'
import { Row, Col } from 'flexbox'
import { colors, fonts } from 'theme'
import { store } from 'services'
import { required, validEmail } from 'services/validators'
import { forgetPassword } from './styles'

class ResetPassword extends Component {
  static propTypes = {
    email: PropTypes.string,
    success: PropTypes.bool,
    loading: PropTypes.bool,
    emailNotFound: PropTypes.bool,
    resetPasswordAction: PropTypes.func.isRequired
  }

  render() {
    const {
      success,
      email,
      resetPasswordAction,
      loading,
      emailNotFound
    } = this.props
    if (!required(email) || !validEmail(email)) {
      return null
    }
    if (loading) {
      return (
        <div className="forget-password">
          <Spinner />
          <style jsx>{forgetPassword}</style>
        </div>
      )
    }
    if (emailNotFound) {
      return (
        <p className="forget-password">
          Correo no encontrado
          <style jsx>{forgetPassword}</style>
        </p>
      )
    }
    if (success) {
      return (
        <p className="forget-password">
          Un correo ha sido enviado con instrucciones para reestrablecer tu
          contraseña.
          <style jsx>{forgetPassword}</style>
        </p>
      )
    }

    return (
      <p className="forget-password">
        ¿Olvidaste tu contraseña? Restablece tu contraseña{' '}
        <span onClick={() => resetPasswordAction({ email })}>
          <b>aquí.</b>
        </span>
        <style jsx>{forgetPassword}</style>
      </p>
    )
  }
}

export default ResetPassword
