import {
  required,
  validMinimalSecurityPassword,
  validLazyEmail,
} from 'services/validators'

const login = values => {
  const errors = {}
  if (!required(values.email)) {
    errors.email = 'Email requerido'
  } else if (!validLazyEmail(values.email)) {
    errors.email = 'Email inválido'
  }
  if (!required(values.password)) {
    errors.password = 'Contraseña requerida'
  }
  return errors
}

export const newPasswordValidator = values => {
  const errors = login(values)

  if (!validMinimalSecurityPassword(values.password)) {
    errors.password =
      'La contraseña debe contener al menos ocho caracteres, un número, una mayúscula y una minúscula.'
  }

  if (!required(values.confirmPassword)) {
    errors.confirmPassword = 'Confirmación Requerida'
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Debe coincider con tu contraseña'
  }

  return errors
}

export default login
