import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Field, reduxForm } from 'redux-form'
import { Button } from 'controls'
import { Row, Col } from 'flexbox'
import { store } from 'services'
import { InputForm, NonFieldInputForm } from 'controls/Input'
import loginValidator from 'app/validators/login'
import { companyConfig } from 'globalConfigApp'

import styles from './styles'
import { common } from '../styles'

class LoginForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    centered: PropTypes.bool,
    invalid: PropTypes.bool,
    style: PropTypes.object
  }

  static defaultProps = {
    handleSubmit: () => console.log('submit from login form'),
    showActions: true,
    centered: true,
    invalid: true,
    style: {}
  }

  render() {
    const { handleSubmit, invalid, style, centered, loading } = this.props
    return (
      <form className="login-container" onSubmit={handleSubmit} style={style}>
        <h3 className={classnames('header', { centered })}>Ingresa a {companyConfig.name}</h3>
        <h4 className={classnames('sub-header', { centered })}>
          Ingresa los detalles de tu cuenta a continuación
        </h4>
        <Row>
          <Col xs={12}>
            <Field
              className="form-input-margin"
              component={NonFieldInputForm}
              name="nonFieldErrors"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Field
              className="form-input-margin"
              name="email"
              id="email"
              component={InputForm}
              label="Correo electrónico"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Field
              className="form-input-margin"
              name="password"
              id="password"
              type="password"
              component={InputForm}
              label="Contraseña"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="actions">
            <Button
              color="primary"
              fullWidth
              type="submit"
              className="submit"
              disabled={invalid}
              loading={loading}
            >
              Entrar
            </Button>
          </Col>
        </Row>
        <style jsx>{styles}</style>
        <style jsx>{common}</style>
      </form>
    )
  }
}

const formConfig = {
  form: 'loginForm',
  validate: loginValidator
}

export const ReduxLoginForm = reduxForm(formConfig)(LoginForm)

export default LoginForm
