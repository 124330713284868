import { colors, fonts, fontSizes } from 'theme'
import css from 'styled-jsx/css'

export default css`
  .login-container {
    display: flex;
    flex-direction: column;
    height: auto;
    margin: 0 auto;
    max-width: 540px;
    width: 100%;
  }

  @media (max-width: 991px) {
    .login-container {
      margin-top: 0;
    }
  }

  .login-container :global(.actions) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .login-container :global(.submit) {
    width: 160px;
    margin: 10px 0;
  }
`

export const forgetPassword = css`
  .forget-password {
    text-align: center;
    color: ${colors.loginText};;
    font: ${fonts.medium};
    font-size: ${fontSizes.p};
    margin: 2rem auto;
    justify-content: center;
    width: 100%;
  }

  .forget-password > span {
    cursor: pointer;
    color: ${colors.primary};
  }
`
