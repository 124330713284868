import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { useDispatch } from 'react-redux'
import { ContactJourneyCreators } from 'app/reducers/ContactJourneyRedux.js'
import { Field, reduxForm } from 'redux-form'
import { iadProvitionalColors } from 'theme/colors'

import { image } from 'services'
import { InputForm, Button } from 'controls'
import { companyConfig } from 'globalConfigApp'

import styles from './styles.js'

const SimpleLogin = ({
  title,
  label,
  topIcon,
  description,
  textToEvaluate,
  inputType,
  handleSubmit,
  managerTags,
  loading
}) => {
  const dispatch = useDispatch()

  return (
    <form onSubmit={handleSubmit}>
      <div
        className="simple-login-container-background"
        style={{ backgroundColor: iadProvitionalColors.white }}
      >
        <div className="simple-login-container">
            <a href="/" target="_blank" style={{ margin: '0 auto' }}>
              <img className="logo" src={image('logo-blue.svg')} alt="logo" />
            </a>
          <img className="top-icon" src={topIcon} alt="user" />
          {title && <h2 className="title">{title}</h2>}

          {description}

          <div className="text-to-evaluate">{textToEvaluate}</div>

          <Field name="email" type="hidden" component="input" />
          <Field name="telephone" type="hidden" component="input" />

          <Field
            label={label}
            name="password"
            style={{color: iadProvitionalColors.black}}
            type={inputType}
            className="check-password"
            onFocus={() => dispatch(ContactJourneyCreators.sendContactJourneyEvent(managerTags.section, managerTags.onFocusAction))}
            component={InputForm}
          />

          <div className="controls">
            <Button
              fullWidth
              style={{backgroundColor: iadProvitionalColors.primary, color: iadProvitionalColors.white}}
              type="submit"
              loading={loading}
              onClick={() => dispatch(ContactJourneyCreators.sendContactJourneyEvent(managerTags.section, managerTags.confirmAction))}
            >
              Entrar
            </Button>
          </div>
        </div>
      </div>
      <style jsx>{styles}</style>
    </form>
  )
}

SimpleLogin.defaultProps = {
  title: `Bienvenido a ${companyConfig.name}`,
  label: 'Agregue los datos correctos',
  textToEvaluate: 'ejemadasdasdasdsad***@nex****om',
  inputType: 'text',
  managerTags: {},
  topIcon: image('/icons/icono-login-block-user.svg'),
  backgroundImage: image('/login/bg-img-login.png')
}

SimpleLogin.propTypes = {
  title: PropTypes.string,
  topIcon: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  textToEvaluate: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.string,
}

const formConfig = {
  form: 'loginForm',
  validate: values => {
    const errors = {}
    if (!values.password) {
      errors.password = 'Requerido'
    }
    return errors
  }
}

export default reduxForm(formConfig)(SimpleLogin)
