import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { stopSubmit, clearFields } from 'redux-form'
import { image, utils } from 'services'
import store from 'services/store'

import { Button } from 'controls'

import { ReduxSignupForm } from 'components/Forms/Login/SignupForm'

class ConnectedLoginForm extends Component {
  static propTypes = {
    signupState: PropTypes.object.isRequired,
    style: PropTypes.object,
    centered: PropTypes.bool,
    actions: PropTypes.shape({
      signup: PropTypes.func.isRequired,
      stopSubmit: PropTypes.func.isRequired,
      clearFields: PropTypes.func.isRequired
    }).isRequired
  }

  constructor(props) {
    super(props)
    this.state = { formValues: {}, success: false }
  }

  valuesChanged = formValues => {
    const { formValues: currentValues } = this.state
    if (!utils.shallowEqual(currentValues, formValues)) {
      this.setState({ formValues })
      this.props.actions.clearFields(
        'signupForm',
        false,
        false,
        'nonFieldErrors'
      )
    }
  }

  getFirstError = errors =>
    Array.isArray(errors) && errors.length > 0 ? errors[0] : errors

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.signupState.loading && nextProps.signupState.error) {
      const {
        error: { nonFieldErrors, ...fieldErrors }
      } = nextProps.signupState
      const errors = [
        ...Object.keys(fieldErrors).map(e => ({
          [e]: this.getFirstError(fieldErrors[e])
        })),
        nonFieldErrors
          ? { nonFieldErrors: this.getFirstError(nonFieldErrors) }
          : {}
      ].reduce((acc, value) => ({ ...acc, ...value }))
      this.props.actions.stopSubmit('signupForm', errors)
    }
  }

  performSignup = values => {
    const {
      email,
      firstName,
      lastName,
      secondLastName,
      telephone,
      secondTelephone,
      password
    } = values
    this.props.actions.signup(
      email,
      firstName,
      lastName,
      secondLastName,
      telephone,
      secondTelephone,
      password,
      'BROKER'
    )
  }

  render() {
    const { success } = this.state
    const { signupState, actions, ...rest } = this.props
    return success ? (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <h2>Gracias por enviar tus datos, en breve te contactaremos</h2>
        <Button color="primary" size="large" target="_blank" href="/">
          Continuar
        </Button>
      </div>
    ) : (
      <ReduxSignupForm
        {...rest}
        onSubmit={this.performSignup}
        onChange={this.valuesChanged}
        loading={signupState.loading}
      />
    )
  }
}

const mapStateToProps = state => ({
  signupState: store.signup('oauth').selector(state)
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      signup: store.signup('oauth').action,
      stopSubmit,
      clearFields
    },
    dispatch
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedLoginForm)
