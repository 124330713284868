import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import config from 'config'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { stopSubmit, clearFields } from 'redux-form'
import { image, utils } from 'services'
import store from 'services/store'

import { ReduxLoginForm } from 'components/Forms/Login/LoginForm'
import { ReduxLoginFormIad } from 'components/Forms/Login/IADLogin/LoginForm'
import ContactDashboardLogin from 'components/Login/SimpleLogin'
import ResetPassword from './ResetPassword'
import ResetPasswordIad from 'components/Forms/Login/IADLogin/ResetPassword'

const COMPANY_THEME = config('COMPANY_THEME')
const IS_IAD_THEME = COMPANY_THEME !== 'NEXIMO'


class ConnectedLoginForm extends Component {
  static propTypes = {
    loginRequestState: PropTypes.object.isRequired,
    style: PropTypes.object,
    centered: PropTypes.bool,
    actions: PropTypes.shape({
      loginRequest: PropTypes.func.isRequired,
      stopSubmit: PropTypes.func.isRequired,
      clearFields: PropTypes.func.isRequired
    }).isRequired,
    extraValidationErrorFields: PropTypes.object,
    typeOfLogin: PropTypes.oneOf(['broker', 'contact']),
    loginBy: PropTypes.oneOf(['email', 'telephone'])
  }

  static defaultProps = {
    typeOfLogin: 'broker',
    loginBy: 'email',
    extraValidationErrorFields: {}
  }

  constructor(props) {
    super(props)
    this.state = { formValues: {}, resetPassword: false, emailNotFound: false }
  }

  valuesChanged = formValues => {
    const { formValues: currentValues } = this.state
    if (!utils.shallowEqual(currentValues, formValues)) {
      this.setState({ formValues, resetPassword: false, emailNotFound: false })
      this.props.actions.clearFields(
        'loginForm',
        false,
        false,
        'nonFieldErrors'
      )
    }
  }

  getFirstError = errors =>
    Array.isArray(errors) && errors.length > 0 ? errors[0] : errors

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.resetPasswordRequest.loading &&
      !nextProps.resetPasswordRequest.loading &&
      !nextProps.resetPasswordRequest.error
    ) {
      this.setState({ resetPassword: true })
    } else if (
      this.props.resetPasswordRequest.loading &&
      !nextProps.resetPasswordRequest.loading &&
      nextProps.resetPasswordRequest.error
    ) {
      this.setState({ emailNotFound: true })
    }
    if (
      this.props.loginRequestState.loading &&
      nextProps.loginRequestState.error
    ) {
      const {
        error: { nonFieldErrors, ...fieldErrors }
      } = nextProps.loginRequestState
      const errors = [
        this.props.extraValidationErrorFields,
        ...Object.keys(fieldErrors).map(e => ({
          [e]: this.getFirstError(fieldErrors[e])
        })),
        nonFieldErrors
          ? { nonFieldErrors: this.getFirstError(nonFieldErrors) }
          : {}
      ].reduce((acc, value) => ({ ...acc, ...value }))
      this.props.actions.stopSubmit('loginForm', errors)
    }
  }

  performLogin = values => {
    const { actions, typeOfLogin, loginBy, fingerprint} = this.props
    const { email, password } = values

    if(typeOfLogin === 'contact' && loginBy === 'telephone') {
      actions.loginRequestContact('', password)
    } else if(typeOfLogin === 'contact' && loginBy === 'email') {
      actions.loginRequestContact(password, '')
    } else {
      actions.loginRequest(email, password, fingerprint)
    }
  }

  render() {
    const {
      loginRequestState,
      loginContactRequestState,
      actions,
      resetPasswordRequest: { loading },
      typeOfLogin,
      initialValues,
      loginConfig,
      ...rest
    } = this.props
    const { resetPassword, formValues, emailNotFound } = this.state

    return (
      <div>
        {typeOfLogin === 'broker' &&
          (IS_IAD_THEME ? (
            <ReduxLoginFormIad
              {...rest}
              onSubmit={this.performLogin}
              onChange={this.valuesChanged}
              loading={loginRequestState && loginRequestState.loading}
            />
          ) : (
            <ReduxLoginForm
              {...rest}
              onSubmit={this.performLogin}
              onChange={this.valuesChanged}
              loading={loginRequestState && loginRequestState.loading}
            />
          ))}
        {typeOfLogin === 'contact' && (
          <ContactDashboardLogin
            {...loginConfig}
            initialValues={initialValues}
            onSubmit={this.performLogin}
            loading={loginRequestState && loginRequestState.loading}
          />
        )}

        {IS_IAD_THEME ?
          <ResetPasswordIad
            email={formValues.email}
            success={resetPassword}
            loading={loading}
            emailNotFound={emailNotFound}
            resetPasswordAction={() => actions.resetPassword({ email: formValues.email })}
          /> :
          <ResetPassword
            email={formValues.email}
            success={resetPassword}
            loading={loading}
            emailNotFound={emailNotFound}
            resetPasswordAction={() => actions.resetPassword({ email: formValues.email })}
          />
        }
      </div>
    )
  }
}

const mapStateToProps = (state, { typeOfLogin }) => ({
  loginRequestState:
    (typeOfLogin === 'broker' && store.accessToken('oauth').selector(state)) ||
    (typeOfLogin === 'contact' && store.accessContactToken('oauth').selector(state)),
  resetPasswordRequest: store.publicResetPassword('user').selector(state),
  fingerprint: state.userSession.fingerprint,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loginRequest: store.accessToken('oauth').action,
      loginRequestContact: store.accessContactToken('oauth').action,
      resetPassword: store.publicResetPassword('user').action,
      stopSubmit,
      clearFields
    },
    dispatch
  )
})

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedLoginForm)
